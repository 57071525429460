import React, { useState, useEffect } from "react";
import { withRouter, useLocation, useHistory, Link } from "react-router-dom";
import { Collapse } from "react-bootstrap";
import { t } from "locales";
import useStorage from "reducer";
import ChevronDown from "react-bootstrap-icons/dist/icons/chevron-down";
import dashboard from "react-bootstrap-icons/dist/icons/speedometer2";
import cardlist from "react-bootstrap-icons/dist/icons/card-list";
import orderhistory from "react-bootstrap-icons/dist/icons/clock-history";
import currentOrder from "react-bootstrap-icons/dist/icons/list-task";
import wallet from "react-bootstrap-icons/dist/icons/wallet";
import asset from "react-bootstrap-icons/dist/icons/wallet2";
import withdraw from "react-bootstrap-icons/dist/icons/upload";
import deposit from "react-bootstrap-icons/dist/icons/download";
import depositHistory from "react-bootstrap-icons/dist/icons/box-arrow-down";
import withdrawHistory from "react-bootstrap-icons/dist/icons/box-arrow-up";
import addressBook from "react-bootstrap-icons/dist/icons/journal";
import setting from "react-bootstrap-icons/dist/icons/gear-wide-connected";
import ticket from "react-bootstrap-icons/dist/icons/chat-dots";
import message from "react-bootstrap-icons/dist/icons/envelope";
import level from "react-bootstrap-icons/dist/icons/bar-chart";
import bell from "react-bootstrap-icons/dist/icons/bell";
import referral from "react-bootstrap-icons/dist/icons/gem";
import markets from "react-bootstrap-icons/dist/icons/folder";
import exchange from "react-bootstrap-icons/dist/icons/arrow-left-right";
import Persone from "react-bootstrap-icons/dist/icons/person-fill";
import Verify from "react-bootstrap-icons/dist/icons/bookmark-check-fill";
import NotVerify from "react-bootstrap-icons/dist/icons/bookmark-x";
import Language, { langs } from "component/language";
import Currency, { currencies } from "component/currency";
import Tooltip from "component/tooltip";

const signed = [
  { icon: markets, title: "markets", link: "/markets" },
  { icon: exchange, title: "exchange", link: "/trade/BTC-USDT" },
];
const geuest = [
  { icon: markets, title: "markets", link: "/markets" },
  { icon: exchange, title: "exchange", link: "/trade/BTC-USDT" },
];
const panel = [
  { icon: dashboard, title: "dashboard", link: "/account/dashboard" },
  {
    icon: wallet,
    title: "wallet",
    link: "/account/wallet",
    subs: [
      {
        icon: asset,
        title: "asset",
        link: "/account/wallet/assets",
      },
      {
        icon: deposit,
        title: "deposit",
        link: "/account/wallet/deposit/",
      },
      {
        icon: withdraw,
        title: "withdraw",
        link: "/account/wallet/withdraw/",
      },
      {
        icon: depositHistory,
        title: "depositRecords",
        link: "/account/wallet/deposit-history",
      },
      {
        icon: withdrawHistory,
        title: "withdrawRecords",
        link: "/account/wallet/withdraw-history",
      },
      {
        icon: addressBook,
        title: "cardList",
        link: "/account/wallet/card-list",
      },
    ],
  },
  {
    icon: cardlist,
    title: "order",
    link: "/account/order",
    subs: [
      {
        icon: currentOrder,
        title: "current",
        link: "/account/order/current",
      },
      {
        icon: orderhistory,
        title: "history",
        link: "/account/order/history",
      },
    ],
  },
  { icon: ticket, title: "ticket", link: "/account/ticket" },
  { icon: bell, title: "notification", link: "/account/notification" },
  { icon: message, title: "messages", link: "/account/message" },
  { icon: level, title: "accountLevel", link: "/account/level" },
  { icon: referral, title: "referral", link: "/account/referral" },
  { icon: setting, title: "setting", link: "/account/setting" },
];

const Sidebar = (props) => {
  const { setting, app, setSetting } = useStorage();
  const [state, setState] = useState({});
  const location = useLocation();
  const history = useHistory();

  const { user } = app;
  const toggleMenuState = (menuState) => {
    if (state[menuState]) {
      setState({ [menuState]: false });
    } else if (Object.keys(state).length === 0) {
      setState({ [menuState]: true });
    } else {
      Object.keys(state).forEach((i) => {
        setState({ [i]: false });
      });
      setState({ [menuState]: true });
    }
  };

  useEffect(() => {
    onRouteChanged();
  }, [location]);
  useEffect(() => {
    let active = location.pathname.split("/");
    toggleMenuState("/" + active?.[1] + "/" + active?.[2]);
  }, []);

  const links = props?.Offcanvas
    ? setting.isLoged
      ? [...signed, ...panel]
      : geuest
    : panel;
  useEffect(() => {
    const body = document.querySelector("body");
    document.querySelectorAll(".sidebar .nav-item").forEach((el) => {
      el.addEventListener("mouseover", function () {
        if (body.classList.contains("sidebar-icon-only")) {
          el.classList.add("hover-open");
        }
      });
      el.addEventListener("mouseout", function () {
        if (body.classList.contains("sidebar-icon-only")) {
          el.classList.remove("hover-open");
        }
      });
    });
  }, []);

  const onRouteChanged = () => {
    document.querySelector("#sidebar").classList.remove("active");
  };
  const isPathActive = (path) => {
    return location.pathname.indexOf(path) == 0;
  };
  const showLang = () => {
    app.modal.show(<Language />, { type: "sm", noClose: false });
  };
  const showCurrency = () => {
    app.modal.show(<Currency />, { type: "sm", noClose: false });
  };
  const changetheme = () => {
    setSetting({ theme: setting.theme == "dark" ? "light" : "dark" });
  };
  const logOut = () => {
    history.push("/");
    setSetting(null);
  };
  const langTitle = langs.filter(
    (lang) => lang.id == setting?.lang ?? "en"
  )?.[0]?.title;
  return (
    <nav
      className={
        "sidebar " +
        (props?.Offcanvas ? "navbar-offcanvas " : "sidebar-offcanvas")
      }
      id="sidebar"
    >
      {props?.Offcanvas && !setting.isLoged && (
        <div className="d-grid gap-2 mb-2 p-3">
          <Link to="/login" className={"btn btn-outline-light btn-rounded"}>
            {t("login")}
          </Link>
          <div className="diver" />
          <Link to="/register" className="btn btn-gradient-primary btn-rounded">
            {t("register")}
          </Link>
        </div>
      )}
      <ul className="nav">
        {setting.isLoged && (
          <>
            <li
              className={
                "nav-item d-flex  mb-2 align-items-center " +
                (props?.Offcanvas ? "" : "mt-3")
              }
            >
              <div className="avatar avatar-40 bg-light rounded-circle text-white p-2">
                <Persone className="fs-2 text-dark" />
              </div>
              <div className="d-flex flex-column mx-3 flex-grow-1">
                <span className="font-weight-bold">
                  {user?.name?.split?.("@")?.[0] ?? "user"}
                </span>
                <span className="text-secondary text-small">
                  {t("level") + " " + (user?.verify ?? "-")}
                </span>
              </div>
              <Tooltip title={t(user?.verify > 2 ? "verified" : "notVerified")}>
                {user?.verify ? (
                  <Verify className="text-info" />
                ) : (
                  <NotVerify className="text-danger" />
                )}
              </Tooltip>
            </li>
            <hr className="mx-3" />
          </>
        )}

        {links.map((item, i) =>
          item.subs ? (
            <li
              key={i}
              className={
                "nav-item " + (isPathActive(item.link) ? "active" : "")
              }
            >
              <div
                className={
                  state[item.link] ? "nav-link menu-expanded" : "nav-link"
                }
                onClick={(e) => toggleMenuState(item.link)}
              >
                <item.icon className="text-primary mx-2 fs-5" />
                <span className="menu-title w-100">{t(item.title)}</span>
                <i className="menu-arrow">
                  <ChevronDown />
                </i>
              </div>
              <Collapse in={state[item.link]}>
                <ul className="nav flex-column sub-menu">
                  {item.subs.map((sub, j) => (
                    <li key={j} className="nav-item">
                      <Link
                        className={
                          "nav-link " + (isPathActive(sub.link) ? "active" : "")
                        }
                        to={sub.link}
                      >
                        <sub.icon className="text-primary mx-2 fs-6" />
                        {t(sub.title)}
                      </Link>
                    </li>
                  ))}
                </ul>
              </Collapse>
            </li>
          ) : (
            <li
              key={i}
              className={
                "nav-item " + (isPathActive(item.link) ? "active" : "")
              }
            >
              <Link to={item.link} className="nav-link">
                <item.icon className="text-primary mx-2 fs-5" />
                <span className="menu-title">{t(item.title)}</span>
              </Link>
            </li>
          )
        )}
      </ul>
    </nav>
  );
};

export default withRouter(Sidebar);

// {
//   props?.Offcanvas && (
//     <>
//       <hr className="mx-3" />
//       <div className="d-flex justify-content-between">
//         {setting.isLoged && (
//           <button className="btn btn-link btn-rounded px-3" onClick={logOut}>
//             <Power className="fs-4" />
//           </button>
//         )}
//         <button className="btn btn-link btn-rounded px-3" onClick={changetheme}>
//           {setting.theme == "dark" ? (
//             <Light className="fs-4" />
//           ) : (
//             <Dark className="fs-4" />
//           )}
//         </button>
//         <button
//           className="btn btn-link btn-rounded px-3"
//           onClick={showCurrency}
//         >
//           <CurrencyIcon className="fs-4" />
//         </button>
//         <button className="btn btn-link btn-rounded px-3" onClick={showLang}>
//           {/* {langTitle} */}
//           <Globe className="fs-4" />
//         </button>
//       </div>
//     </>
//   );
// }
