import React, {
  forwardRef,
  useState,
  useImperativeHandle,
  useEffect,
  useContext,
} from "react";
import Modal from "react-bootstrap/Modal";
import CloseButton from "react-bootstrap-icons/dist/icons/x-lg";
import Button from "component/button";

export default forwardRef((props, ref) => {
  const [open, setOpen] = useState(null);
  const [size, setSize] = useState(null);
  const [opt, setOpt] = useState({ canClose: true });
  useImperativeHandle(ref, (...rest) => ({
    show(component, other = "lg") {
      setOpen(component);
      if (typeof other === "object") {
        const { type = "lg", ...option } = other;
        setSize(type);
        setOpt(option);
      } else {
        setSize(other);
      }
    },
    hide() {
      setOpen(null);
    },
  }));
  return (
    <Modal
      show={!!open}
      centered
      size={size}
      onHide={() => setOpen(null)}
      keyboard={true}
    >
      {opt?.canClose && (
        <Button
          type="link position-absolute z-index-1 opp-side-abs"
          onClick={() => setOpen(null)}
        >
          <CloseButton />
        </Button>
      )}
      {open && open}
    </Modal>
  );
});
