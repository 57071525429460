import server from "config/server";
import axios from "axios";
import { isDev } from "./helper";
// import { setup } from "axios-cache-adapter";
import axiosRetry from "axios-retry";
axiosRetry(axios, {
  retries: 5,
});

export const baseUrl = isDev() ? server.development.url : server.production.url;

const axiosBase = axios.create({
  baseURL: baseUrl + "api/",
  timeout: 5000,
  headers: {
    "Content-Type": "application/json",
  },
});

export const get = async (path, opt) => {
  let header = {};
  try {
    if (opt?.cache) {
      header.cache = {
        maxAge: 10 * 60 * 1000,
      };
    }
    const res = await axiosBase.get(path, header);
    const { data } = await res;
    return data;
  } catch (error) {
    window.postMessage({ event: ["error", "try-later"] }, "*");
    return await 0;
  }
};

export const post = async (path, items, opt) => {
  let header = {};
  try {
    if (opt?.cache) {
      header.cache = {
        maxAge: 10 * 60 * 1000,
      };
    }
    if (opt?.file) {
      header.headers = {
        "Content-Type": "multipart/form-data;",
        Token: opt?.token,
      };
    } else {
      // items = new URLSearchParams(items).toString();
    }
    const res = axiosBase.post(path, items, header);
    const { data } = await res;
    return data;
  } catch (error) {
    if (error.response) {
      if (error.response.status == 401) {
        window.postMessage({ login: true }, "*");
      }
      return await error.response.data;
    } else if (error.request) {
    } else {
      return await 0;
    }
    window.postMessage({ event: ["error", "try-later"] }, "*");
  }
};
// export const json = async (path, items, opt) => {
//   let header = { headers: { "Content-Type": "application/json" } };
//   try {
//     if (opt?.cache) {
//       header.cache = {
//         maxAge: 10 * 60 * 1000,
//       };
//     }
//     let form = JSON.stringify(items);
//     const res = axiosBase.post(path, form, header);
//     const { data } = await res;
//     return data;
//   } catch (error) {
//     window.postMessage({ event: ["error", "try-later"] }, "*");
//     return await 0;
//   }
// };
export default { get, post, baseUrl };
