export default (appName) => ({
  homeTitle: "" + appName.name + " Exchange",
  homeSubTitle:
    "Secure trading of bitcoins and cryptocurrency . Join to the largest cryptocurrency market in Iran ",
  kycTrading: "How to make money with cryptocurrency",
  kycTradingDesc:
    "Everyone gets into the cryptocurrency field to make money, but not all end up doing that. A lot of people either simply give up along the way or fall into some sort trap with the rising cases of crypto scams.",
  kyc1: "Quick Registration",
  kyc2: "Select Favorite Coin",
  kyc3: "KYC Authentication",
  kyc4: "Quick Purchase Request",
  kyc5: "Fast Transaction",
  kyc6: "Bonuses And Gifts",
  ourService: "Why Are We Trustworthy?",
  ourServiceDesc:
    "Variety of features that make it the best place to start trading",
  service1: "Security & Stability",
  service1Desc:
    "With top technical team, all-round security protection and our proprietary high-speed matching engine, we guarantee stability under concurrency of thousands of orders, each one of which will be in good hands.",
  service2: "Professional & Compliant",
  service2Desc:
    "Apart from our operation team with rich experience in blockchain and financial industry, we have a 100% reserve guarantee and have obtained a legal license of digital asset.",
  service3: "User-First",
  service3Desc:
    "Serving global users with 24/7 exchange services, multilingual support, strong community backup, and professional customer service.",
  footerAbout: "The Global Digital Coin Exchange",
  aboutUs: "About Us",
  quickLinks: "Quick Links",
  community: "Community",
  about: "About",
  terms: "Terms",
  privacy: "Privacy",
  wallet: "Wallet",
  appTitle: "Build your crypto portfolio anywhere.",
  appText:
    "A powerful cryptocurrency exchange in your pocket. Buy, sell and trade crypto on the go.",
  getApp: "Get the " + appName.name + " Wallet Mobile App Now!",
  Soon: "Soon",
  appPoem: "Your best crypto partner.",
  referralTitle: "Share in " + appName.name + " profit",
  referralDesc:
    "By inviting your friends with yours link, every time they trade, you will  receive up to # of their trading fee from " +
    appName.name +
    "",
  rightReserved: "" + appName.name + " ©. All rights reserved.2021",
});
