import React, {
  forwardRef,
  useState,
  useImperativeHandle,
  useEffect,
  useContext,
} from "react";
import Alert from "react-bootstrap/Alert";

export default forwardRef((props, ref) => {
  const [open, setOpen] = useState(false);
  const [type, setType] = useState("success");
  const [msg, setMsg] = useState("");

  useImperativeHandle(ref, (type, msg) => ({
    show(type, msg) {
      notify(type, msg);
    },
    hide() {
      setOpen(false);
    },
  }));

  const tnotify = (type, msg) => {
    notify(type, msg);
  };
  const notify = (type, msg) => {
    if (msg == "") return;
    setType(type);
    setMsg(msg);
    setOpen(true);
    setTimeout(() => {
      setOpen(false);
    }, 8000);
  };

  const handleClose = (event, reason) => {
    setOpen(false);
  };

  useEffect(() => {
    window.addEventListener("message", (event) => {
      const message = event?.data;
      if (message?.notify) {
        tnotify(...event.data.notify);
      }
    });
  }, []);
  return (
    <div className={"notify " + (open ? "active " : "")}>
      <div className={"f-center"}>
        <Alert
          className={"d-flex align-items-center mb-0"}
          variant={type}
          onClose={handleClose}
          dismissible
        >
          <span>{msg}</span>
        </Alert>
      </div>
    </div>
  );
});
