import React, { Suspense, lazy, useEffect, useRef } from "react";
import {
  Switch,
  Route,
  Redirect,
  useHistory,
  useLocation,
} from "react-router-dom";

import useStorage from "reducer";
import Spinner from "component/spinner";

const route = [
  { path: "/", component: lazy(() => import("route/index")) },
  { path: "/register", component: lazy(() => import("route/sign/register")) },
  { path: "/login", component: lazy(() => import("route/sign/login")) },
  { path: "/forget", component: lazy(() => import("route/sign/forget")) },
  { path: "/verify", component: lazy(() => import("route/sign/verify")) },
  {
    path: "/reset-password",
    component: lazy(() => import("route/sign/reset")),
  },
  {
    path: "/account/*",
    component: lazy(() => import("route/account")),
  },
  {
    path: "/pages/*",
    component: lazy(() => import("route/pages")),
  },
  {
    path: "/markets",
    component: lazy(() => import("route/markets")),
  },
  {
    path: "/trade/:pair",
    component: lazy(() => import("route/trade/trade")),
  },
];
const AppRoutes = (props) => {
  const {
    setting: { isLoged },
  } = useStorage();
  const history = useHistory();
  const loged = useRef(null);

  useEffect(() => {
    if (loged.current) {
      if (isLoged) {
        history.replace("/account/dashboard/");
      } else {
        history.replace("/login");
      }
    }
  }, [isLoged]);
  return (
    <Suspense fallback={<Spinner />}>
      <Switch>
        {route.map((route, i) => (
          <Route key={i} exact path={route.path} component={route.component} />
        ))}
        <Redirect to={"/"} />
      </Switch>
    </Suspense>
  );
};

export default AppRoutes;
