export const hideEmail = function (email) {
  if (!email || email.length == 0) return "-";
  return email.replace(/(.{2})(.*)(?=@)/, (gp1, gp2, gp3) => (gp2 += "*****"));
};
export const hideMobile = function (mobile) {
  if (!mobile || mobile.length == 0) return "-";
  return mobile.replace(/(\d{3})\d{5}(\d{4})/, "$1*****$2");
};
export const hideText = function (txt, count) {
  return (
    txt.substring(0, count) +
    "*****" +
    txt.substring(txt.length - count, txt.length)
  );
};
export const swap = (o, r = {}) =>
  Object.keys(o).map((x) => (r[o[x]] = x)) && r;

export const validateEmail = (email) => {
  let re = /\S+@\S+\.\S+/;
  return re.test(email);
};
export function replaceJSX(str, find, replace) {
  let parts = str.split(find);
  let result = [];
  for (let i = 0; i < parts.length; i++) {
    result.push(parts[i]);
    result.push(replace);
  }
  result.pop();
  return result;
}

export function isDev() {
  return window.location.hostname === "localhost";
}
export function dateTime(date) {
  return new Date(date).toLocaleString("en-US", { timeZone: "Asia/Jakarta" });
}
export function time(date) {
  const d = new Date(date);

  return (
    ("0" + d.getHours()).slice(-2) +
    ":" +
    ("0" + d.getMinutes()).slice(-2) +
    ":" +
    ("0" + d.getSeconds()).slice(-2)
  );
}
export function toMoney2(amount) {
  if (typeof amount == "undefined" || amount == "null") return "";
  const ret = ("" + amount)
    .replace(/[^\d.-]/g, "")
    .replace(/\d(?=(\d{3})+\.)/g, "$&,");
  console.log(ret);
  return ret;
}
export function toMoney1(amount) {
  if (
    typeof amount == "undefined" ||
    amount == "null" ||
    amount === "" ||
    amount === null
  )
    return "";
  if (amount == 0) return 0;
  let p = parseFloat(
    parseFloat(("" + amount).replace(/,/g, "")).toFixed(8)
  ).split(".");
  return (
    p[0].replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,") +
    (p[0].length > 6
      ? ""
      : p[1]
      ? (("." + p[1]) * 1).toString().replace("0.", ".")
      : "")
  );
}
export function toMoney(amount) {
  if ([null, "", undefined].includes(amount)) return "";
  try {
    const parts = parseFloat(
      parseFloat(amount.toString().replace(/,/g, "")).toFixed(8).toString()
    )
      .toString()
      .split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    const res = parts[0].length < 6 ? parts.join(".") : parts[0];
    return res;
  } catch (error) {
    return "";
  }
}
export function minMoney(num) {
  if (num >= 1000000000) {
    return (num / 1000000000).toFixed(1).replace(/\.0$/, "") + "B";
  }
  if (num >= 1000000) {
    return (num / 1000000).toFixed(1).replace(/\.0$/, "") + "M";
  }
  if (num >= 1000) {
    return (num / 1000).toFixed(1).replace(/\.0$/, "") + "K";
  } else {
    return "1K";
  }
  return num;
}

export function num(txt) {
  let ret = 0;
  if (typeof txt == "string") ret = txt.replace(/[^\d\.]*/g, "");
  else ret = txt;
  return parseInt(ret);
}
export function isFloat(num) {
  return typeof num === "number" && !Number.isInteger(num);
}
export function query(param) {
  let xquery = window.location.search.substring(1);
  let vars = xquery.split("&");
  for (let i = 0; i < vars.length; i++) {
    let pair = vars[i].split("=");
    if (pair[0] == param) {
      return pair[1];
    }
  }
  return false;
}

export function format(number, mask) {
  number = (number ?? "").replace(/\D/g, "");
  var s = "" + number,
    r = "";
  for (var im = 0, is = 0; im < mask.length && is < s.length; im++) {
    r += mask.charAt(im) == "X" ? s.charAt(is++) : mask.charAt(im);
  }
  return r;
}
