import React from "react";
import useStorage from "reducer";

export default React.memo(({ time, justTime = false }) => {
  const {
    setting: { lang },
  } = useStorage();

  const date = new Date(time);
  let _date = " ";
  if (justTime) {
    _date =
      lang == "fa"
        ? date.toLocaleTimeString("fa-IR-u-nu-latn").replace("،", " ")
        : date.toLocaleTimeString().replace(",", " ");
  } else {
    _date =
      lang == "fa"
        ? date.toLocaleString("fa-IR-u-nu-latn").replace("،", " ")
        : date.toLocaleString().replace(",", " ");
  }
  return <bdi className="dir-ltr nowrap">{_date}</bdi>;
});
