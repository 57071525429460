import createPersistedReducer from "../persist";

export default createPersistedReducer("wallet");

export const initialState = [];
export function reducer(state, action) {
  if (action == null) {
    return [...initialState];
  } else {
    let temp = {};
    if (Array.isArray(action)) {
      for (let i of action) {
        temp[i.asset] = i;
      }
      return { ...state, ...temp };
    }
  }
  return state;
}
