export default (appName) => ({
  homeTitle: "صرافی " + appName.name + "",
  homeSubTitle:
    "خرید و فروش امن بیت‌کوین و ارزهای دیجیتال . به بزرگترین بازار ارز دیجیتال ایران بپیوندید ",
  kycTrading: "درآمدزایی با ارز دیجیتال را شروع کنید",
  kycTradingDesc:
    "همه برای کسب درآمد وارد حوزه ارزهای رمزنگاری شده می شوند . بسیاری از مردم یا به سادگی در طول راه تسلیم می شوند یا با افزایش موارد کلاهبرداری های رمزنگاری به دام می افتند.",
  kyc1: "ثبت‌ نام سریع",
  kyc2: "انتخاب ارز مورد نظر",
  kyc3: "احراز هویت برای فروش",
  kyc4: "درخواست خرید سریع",
  kyc5: "انجام سریع تراکنش",
  kyc6: "بونوس و هدایا",

  ourService: "چرا این پلتفوم قابل اعتماد است؟",
  ourServiceDesc:
    "با امکانات خاص و یکتا امن ترین بستر خرید و فروش ارز دیجیتال را برای شما فراهم کرده ایم.",
  service1: "پایدار و امن",
  service1Desc:
    "با تیم فنی برتری، حفاظب از امنیت در همه جانبه و موتور کارآمد انحصاری در مطابقت تراکنش، ما تضمین می کنیم که زیر همزمانی سفارشات فراوان هم بتواند به طور پایدار اجرا کند.",
  service2: "حرفه‌ای و قانونی",
  service2Desc:
    "تیم عملیات حرفه‌ای با تجارب سالها راجع به صنایع زنجیره بلوکی و مالی هستیم، صد درصد ضمانت داریم، همچنین گواهینامه قانونی راجع به معاملات دارایی دیجیتال داریم.",
  service3: "کاربر - محور",
  service3Desc:
    "خدمت به کاربران جهانی با پشتیبانی چندزبانه، خدمات ۲۴ در ۷، پشتیبانی اجتماعات قوی، سرویس کاربران حرفه‌ای",
  footerAbout: "صرافی ارز دیجیتال جهانس",
  aboutUs: "درباره ما",
  quickLinks: "لینک های سریع",
  community: "شبکه های اجتماعی",
  about: "درباره ما",
  terms: "قوانین و مقررات",
  privacy: "حریم خصوصی",
  wallet: "کیف پول",
  appTitle: "پورتفوی ارز های دیجیتال خود را به راحتی مدیریت کنید.",
  appText: "جهت معاملات خود از پشتیبانی 24 ساعته ی ما در خواست مشاوره بکنید",
  getApp: "کیف پول " + appName.name + " را در موبایل خود دانلود کنید!",
  Soon: "به زودی",
  referralTitle: "در سود " + appName.name + " سهیم شوید",
  referralDesc:
    "با دعوت از دوستانتان با لینک اختصاصی خود، هر بار که معامله می‌کنند برای همیشه تا # از کارمزد معاملاتشان از طرف " +
    appName.name +
    " به شما تعلق می‌گیرد",
  rightReserved: "تمامی حقوق این سایت برای کونکوردکس محفوط است . 2021",
});
