import React from "react";
import Button from "component/button";
import useStorage from "reducer";

export const langs = [
  { title: "English", id: "en" },
  { title: "فارسی", id: "fa", className: "font-fa" },
  // { title: "Türkçe", id: "tr" },
  // { title: "العربية", id: "ar", className: "font-fa" },
];

export default function () {
  const { setting, setSetting, app } = useStorage();
  const changeLang = (lang) => {
    app.modal.hide();
    setSetting({ lang });
  };
  return (
    <div className={"row my-3 px-2"}>
      {langs.map((lang, i) => (
        <Button
          key={i}
          onClick={() => changeLang(lang.id)}
          type={
            "link col-sm-6 col-md-6 my-2 " +
            (setting.lang == lang.id ? "text-primary " : "") +
            (lang.className ?? "")
          }
        >
          {lang.title}
        </Button>
      ))}
    </div>
  );
}
