import React from "react";

export default function ({ inline = false, absolute = false }) {
  return (
    <div className={"text-center p-4 " + (absolute ? "absolute-loading" : "")}>
      <div className="spinner-border" role="status">
        <span className="visually-hidden">Loading...</span>
      </div>
    </div>
  );
}
