import React, {
  forwardRef,
  useState,
  useImperativeHandle,
  useEffect,
  useContext,
} from "react";
import { t } from "locales";
import Modal from "react-bootstrap/Modal";
import Button from "component/button";

const Danger = () => (
  <div className="screenAlert-icon screenAlert-error animate">
    <span className="screenAlert-x-mark">
      <span className="screenAlert-line screenAlert-left animateXLeft"></span>
      <span className="screenAlert-line screenAlert-right animateXRight"></span>
    </span>
    <div className="screenAlert-placeholder"></div>
    <div className="screenAlert-fix"></div>
  </div>
);
const Warning = () => (
  <div className="screenAlert-icon screenAlert-warning scaleWarning">
    <span className="screenAlert-body pulseWarningIns"></span>
    <span className="screenAlert-dot pulseWarningIns"></span>
  </div>
);
const Info = () => (
  <div className="screenAlert-icon screenAlert-info scaleInfo">
    <span className="screenAlert-dot pulseInfoIns"></span>
    <span className="screenAlert-body pulseInfoIns"></span>
  </div>
);
const Success = () => (
  <div className="screenAlert-icon screenAlert-success animate">
    <span className="screenAlert-line screenAlert-tip animateSuccessTip"></span>
    <span className="screenAlert-line screenAlert-long animateSuccessLong"></span>
    <div className="screenAlert-placeholder"></div>
    <div className="screenAlert-fix"></div>
  </div>
);

export default forwardRef((props, ref) => {
  const [type, setType] = useState("success");
  const [open, setOpen] = useState(null);

  useImperativeHandle(ref, (...rest) => ({
    danger(rest) {
      setType("danger");
      setOpen(rest);
    },
    info(rest) {
      setType("info");
      setOpen(rest);
    },
    warning(rest) {
      setType("warning");
      setOpen(rest);
    },
    success(rest) {
      setType("success");
      setOpen(rest);
    },
  }));
  const done = () => {
    setOpen(null);
    if (open?.callback) {
      open?.callback();
    }
  };
  return (
    <Modal
      show={!!open}
      centered
      size="md"
      backdrop="static"
      keyboard={false}
      dialogClassName="alert-box"
    >
      {open && (
        <>
          {type == "danger" && <Danger />}
          {type == "info" && <Info />}
          {type == "warning" && <Warning />}
          {type == "success" && <Success />}
          <div className="my-4">
            {open?.title && (
              <h5 className="text-center">
                <bdi>
                  {open?.noTranslate == true ? open.title : t(open.title)}
                </bdi>
              </h5>
            )}
            {open?.text && (
              <div className="mt-2 text-center">
                {open?.noTranslate == true ? open.text : t(open.text)}
              </div>
            )}
          </div>
        </>
      )}
      <div className="d-grid gap-2 p-3">
        <Button type={type} onClick={done}>
          {t(open?.confirm ?? "understand")}
        </Button>
      </div>
    </Modal>
  );
});
