import index from "./index-fa";
const appName = { name: "ریلتکس", nameOne: "ریل", nameTwo: "تکس" };

export default {
  ...index(appName),
  xbrand: "RealTex",
  brand: appName.name,
  nameOne: appName.nameOne,
  nameTwo: appName.nameTwo,
  register: "ثبت نام",
  dashboard: "پنل کاربری",
  current: "اخیر",
  history: "تاریخچه",
  order: "سفارشات",
  wallet: "کیف پول",
  deposit: "واریز",
  depositRecords: "لیست واریز",
  withdraw: "برداشت",
  verifyWithdraw: "تایید برداشت",
  withdrawRecords: "لیست برداشت",
  accountSetting: "تنظیمات حساب کاربری",
  accountLevel: "سطح کاربری",
  referralReward: "جایزه دعوت از دوستان",
  messages: "پیغام ها",
  markets: "بازار",
  exchange: "صرافی",
  trade: "خرید و فروش",
  help: "نیاز به کمک",
  registerTitle: "ثبت نام",
  registerDesc: "به " + appName.name + " خوش آمدید",
  email: "ایمیل",
  mobile: "موبایل",
  password: "رمز عبور",
  createAccount: "افتتاح حساب کاربری",
  referral: "دعوت از دوستان",
  referralId: "کد دعوت (اختیاری)",
  agreeTerms: "من # را خوانده ام و با آن موافقم.",
  TermsOfService: "شرایط خدمات",
  user_exist: "این کاربر قبلا ثبت نام کرده است",
  user_not_found: "کاربر ثبت نام نکرده است",
  invalid_password: "رمز عبور نامعتبر می باشد",
  understand: "متوجه شدم",
  verify: "تایید",
  verified: "تایید شده",
  notVerified: "تایید نشده است",
  verifytitle: "تایید حساب کاربری",
  verifyDesc:
    "لطفا کد ارسالی به (#) را وارد نمایید . اعتبار کد تایید 30 دقیقه می باشد.",
  invalid_code: "کد وارده معتبر نمی باشد لطفا مجددا تلاش نمایید",
  regestredAsk: "آیا ثبت نام نموده اید ؟",
  resend: "ارسال مجدد",
  login: "ورود",
  loginTitle: "ورود به سایت",
  loginDesc: "ورود با شماره موبایل یا ایمیل کاربری",
  registration: "ثبت نام",
  forgetPassword: "فراموشی رمز عبور ؟",
  unexpectedError: "خطای پیش بینی نشده",
  change: "تغییر",
  highest: "بالا",
  lowest: "پایین",
  value: "مقدار",
  buy: "خرید",
  sell: "فروش",
  both: "هردو",
  pair: "جفت ارز",
  lastPrice: "قیمت اخیر",
  /* dashboard */
  basicInfo: "اطلاعات پایه",
  bind: "فعال سازی",
  modify: "تغییر",
  nickName: "نام کامل",
  idVerification: "احراز هویت",
  real: "حقیقی",
  legal: "حقوقی",
  totpAuthentication: "احراز هویت دو مرحله ای",
  totp: "کد دو مرحله ای",
  yes: "بله",
  no: "خیر",
  reset: "ریست",
  unbound: "عدم تثبیت",
  bound: "تثبیت شده",
  next: "مرحله بعد",
  enter2FA: "کد دومرحله ای را وارد نمایید",
  done: "تمام",
  spotAsset: "ارز های اسپات",
  asset: "ارز ها",
  coin: "نوع ارز",
  name: "نام",
  amount: "مقدار",
  available: "فراهم",
  frozen: "مسدود شده",
  marketValue: "ارزش بازار",
  operation: "عملیات",
  attention: "توجه",
  attentionInfo: "* جهت افزایش امنیت کیف پول لطفا موارد زیر را رعایت نمایید",
  attentionDeposit1:
    "آدرس صرفا در شبکه ی  [network] موجود می باشد. واریز ارز به شبکه های دیگر ممکن است موجب مفقود شدن ارز شما شود.",
  attentionDeposit2:
    "کمترین مقدار جهت واریز : [min]. درصورت واریز مقدار ناکافی حساب شما شارژ نخواهد شد.",
  attentionDeposit3:
    "واریز شما بعد از تایید تعداد [confirm] در شبکه شارژ خواهد شد.",
  attentionDeposit4: "واریز شما بعد از تایید  [unlock] اعمال خواهد شد",
  protocolType: "نوع پروتکل",
  depositAttention: "باید قبل از واریز # بخوانید",
  depositConfirm: "از ریسک واریز آگاه هستم و میخواهم ادامه دهم",
  confirm: "تایید",
  copied: "کپی شد",
  attentionWithdraw1:
    "توجه داشته باشید شبکه ی برداشت شما با آدرس مقصد همخوانی داشته باشد در غیر احتمال عدم انتفال ارز می باشد",
  attentionWithdraw2:
    "بعد از درخواست ارسال و تایید مقدار شما ارسال خواهد شد. زمان ارسال بستگی به تعداد تایید حداقل در شبکه دارد.",
  attentionWithdraw3:
    "حداقل مقدار در برداشت: [min]. مقادیر کمتر از حداقل ارسال نمیشود",
  withdrawInfo: "* آدرس برداشت روی شبکه ی  # می باشد",
  depositInfo: "* آدرس واریز ارز بر روی شبکه ی  # می باشد",
  address: "آدرس کیف پول",
  optionTag: "تگ ، ممو (اختیاری)",
  amount: "مقدار",
  submit: "ثبت",
  balance: "موجودی",
  available: "موجود",
  inOrder: "در سفارش",
  pending: "در انتظار",
  executionValue: "مقدار تخمینی عملیات",
  marketPsition: "خرید در سفارش",
  back: "بازگشت",
  recentTradeActivity: "خرید و فروش های اخیر",
  marketOverview: "بازار در یک نگاه",
  sellOrders: "سفارشات فروش",
  buyOrders: "سفارشات خرید",
  depositAddress: "آدرس فرستنده",
  withdrawAddress: "آدرس گیرنده",
  auditing: "در حال بررسی",
  rejected: "رد شده",
  completed: "تمام شده",
  sended: "ارسال شد",
  emptyList: "داده ای یافت نشد",
  successfull: "با موفقیت",
  withdrawDetails: "درخواست برداشت شما در حال بررسی است",
  verifywDetails: "اطلاعات شما با موفقیت ثبت شد و در حال بررسی می باشد",
  limit: "دلخواه",
  market: "بازار",
  stopLimit: "استاپ دلخواه",
  stopMarket: "استاپ بازار",
  time: "زمان",
  side: "ساید",
  type: "نوع",
  status: "وضعیت",
  price: "قیمت",
  totpDesc:
    "کلید خصوصی می تواند به شما در تنظیم مجدد احراز هویت TOTP کمک کند. اگر کلید به اشتباه حذف شده است ، می توانید آن را به صورت دستی وارد کنید تا بازیابی شود.",
  totpUse:
    "برای اسکن این کد QR یا تایپ کلید خصوصی به صورت دستی از احراز هویت TOTP استفاده کنید. کلید خصوصی خود را ذخیره یا یادداشت کنید.",
  copyClipboard: "کپی به کلیپ بورد",
  enterOTP: "کد OTP را وارد کنید",
  enterOTPwithdraw: "کد OTP ارسال شده به ایمیل / موبایل خود را وارد کنید",
  privateKey: "کلید خصوصی",
  verificationDesc:
    "لطفاً از اسناد هویت معتبر خود برای تأیید اطمینان حاصل کنید. ",
  idNumber: "شماره ملی",
  homePhone: "تلفن ثابت",
  zipcode: "کد پستی",
  homeAddress: "آدرس",
  evidenceTitle: "تصویر سند یا اجاره نامه محل سکونت",
  legalDesc: "احراز هویت مخصوص شرکتی",
  legalChangeAds: "آگهی آخرین تغییرات شرکت",
  legalIntroduced: "معرفی نامه شرکت با نامه رسمی و مهر و امضا رو سربرگ",
  legalEconomicCode: "گواهی کد اقتصادی",
  legalPhone: "تلفن ثابت شرکت",
  idCardTitle: "تصویر از کارت ملی",
  idCardNotify:
    "*لطفاً اطمینان حاصل کنید که اسناد و اعلامیه به وضوح قابل مشاهده است و متن کاملاً نمایان است.",
  fullImageTitle: "عکس کامل",
  fullImageNotify:
    "عکس کارت ملی و تاریخ امروز در دست گرفته و چهره تان در تصویر نمایان باشد",
  bad_file: "تصویر یا تصاویر ارسالی ناقص می باشد",
  currentPassword: "رمز عبور فعلی",
  newPassword: "رمز عبور جدید",
  rePassword: "تکرار رمز عبور",
  passwordChanged: "رمز عبور با موفقیت تغییر یافت",
  emailTo: "ارسال ایمیل به",
  smsTo: "ارسال اس ام اس به",
  forgetTitle: "بازنشانی رمز عبور",
  resetPasswordDesc: "بازنشانی رمز عبور شما",
  buyCrypto: "خرید رمز ارز",
  spend: "خرج",
  receive: "دریافتی",
  balance_is_not_enough: "موجودی کافی نیست",
  min_trade_error: " # : حداقل ترید",
  executed: "انجام شده",
  unExecuted: "انجام نشده",
  avgPrice: "میانگین قیمت",
  logout: "خروج",
  setting: "تنظیمات",
  referralDesc:
    "با دعوت از دوستانتان # درصداز کارمزد خرید و فروششان به شما تعلق می گیرد",
  referralCode: "کد معرف",
  referralLink: "لینک اشتراک",
  referralInvited: "دعوت شده",
  generateLink: "ایجاد لینک جدید",
  reward: "پاداش",
  invited: "دعوت شده",
  ticket: "تیکت",
  vandar: "وندار",
  nextpay: "نکست پی",
  chargeAmount: "مبلغ مورد نظر (تومان)",
  levelType: "سطح کاربری",
  takerFee: "کارمزد دریافت کننده",
  makerFee: "کارمزد سازنده",
  avgTrade: "میانگین معامله",
  levelTerms: "شرایط",
  apply: "درخواست",
  total: "جمع کل",
  daylyCryptoLimit: "برداشت روزانه رمزارز",
  daylyFiatLimit: "برداشت روزانه ریالی",
  remain: "باقی مانده",
  used: "استفاده شده",
  normal: "عادی",
  levelUp: "ارتقای سطح کاربری",
  tickets: "تیکت ها",
  newTicket: "تیکت جدید",
  viewTicket: "مشاهده تیکت",
  title: "عنوان",
  text: "توضیحات",
  category: "دسته بندی",
  open: "باز",
  cardId: "شماره حساب",
  bankName: "نام بانک",
  cardNumber: "شماره کارت",
  iban: "شبا",
  showAll: "نمایش همه",
  cancel: "انصراف",
  accepted: "قبول شده",
  filling: "در سفارش",
  incompleted: "ناقص",
  uncompleted: "ناقص",
  canceled: "رد شده",
  filled: "تمام شده",
  view: "مشاهده",
  delete: "حذف",
  edit: "ویرایش",
  firstBindTotp: "برای دسترسی به این صفحه ، کد دو مرحله ای را فعال کنید",
  bind2fa: "فعال سازی کد دو مرحله ای",
  firstBindMobile: "شماره موبایل خود را ثبت کنید",
  addressBook: "آدرس بوک",
  addCard: "افزودن کارت",
  addAddress: "افزودن آدرس",
  local: "سایت",
  global: "جهانی",
  level: "سطح",
  emptyWallet: "کیف پول شما خالی است",
  depositNow: "همین الان شارژ کنید",
  stopPrice: "قیمت استاپ",
  success_payment: "پرداخت با موفقیت انجام شد",
  unsuccess_payment: "پرداخت با مشکل مواجه شد",
  saveToAddressBook: "ذخیره در آدرس بوک",
  cardList: "کارت بانکی",
  trades: "معاملات",
  or: "یا",
  loginWithGoogle: "ورود از طریق گوگل",
  signUpWithGoogle: "ثبت نام از طریق گوگل",
  botDetected: "ربات تشخیص داده شد",
  withdrawFee: "هزینه انتقال",
  withdrawable: "قابل برداشت",
  max: "کل دارایی",
  selected: "انتخاب شده",
  perMonth: "در ماه",
  vip: "VIP",
  verification: "تاییده امنیتی",
  attach: "پیوست",
  ticketSubmited: "تیکت با موفقیت ارسال شد",
  volume: "حجم",
  tag: "تگ",
  orderFee: "کمیسیون",
  quantity: "مقدار",
  details: "جزییات",
  orderDetail: "جزییات سفارش",
  tradeDetail: "جزییات معامله",
  currentOrders: "سفارشات فعلی",
  finishedOrders: "تاریخچه سفارشات",
  devices: "اطلاعات ورود",
  activeDevice: "دستگاه های فعال",
  manage: "مدیریت",
  terminate: "بستن",
  os: "سیستم عامل",
  browser: "مرورگر",
  platform: "پلتفرم",
  ip: "آی پی",
  notification: "اعلانات",
  messages: "پیام ها",
  notificationList: "لیست اعلانات",
  viewAll: "مشاهده همه",
  noNotification: "اعلان جدید وجود ندارد",
  noMessage: "پیام جدید وجود ندارد",
  new_login: "ورود جدید با آی پی ([ip])",
  new_deposit: "[amount] [coin] به حساب شما واریز شد",
  withdraw_accepted: "درخواست برداشت برای ارز [coin] تایید شد",
  withdraw_rejected: "درخواست برداشت برای ارز [coin] رد شد",
  kyc_accept: "درخواست شما برای احراز هویت مرحله [level] تایید شد",
  kyc_reject: "درخواست شما برای احراز هویت مرحله [level] رد شد",
  ticket_replyed: "به تیکت شما پاسخ داده شد",
  validAmount:
    "فیلد :attribute باید بین حداقل برداشت شبکه و موجودی قابل برداشت باشد",
  coinSearch: "جست وجوی ارز",
  filter: "فیلتر",
  invalid_parameters: "ورودی غیر مجاز",
  fromDate: "از تاریخ",
  toDate: "تا تاریخ",
  order_open: "باز",
  order_progress: "در حال بررسی",
  order_done: "بسته شده",
  upperCase: "حرف بزرگ",
  lowerCase: "حرف کوچک",
  number: "عدد",
  topGainers: "بیشترین سود",
  topLosers: "بیشترین ضرر",
  newCoins: "جدیدترین ارزها",
  topValues: "با ارزش ترین ها",
  withdrawLevel: "سطح برداشت",
  tradeLevel: "سطح ترید",
  assetCantDeposit: "این ارز قابل واریز نیست !",
  assetCantWithdraw: "این ارز قابل بردا نیست !",
  totpDeleteLimit: "در صورت حذف TOTP شما ۲۴ ساعت حق برداشت ارز نخواهید داشت",
};
