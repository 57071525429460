import React, { useEffect, useState } from "react";
import useStorage from "reducer";
import { t } from "locales";
import EmptyList from "component/empty-list";
import { Link } from "react-router-dom";
import Time from "component/time";
import Bell from "react-bootstrap-icons/dist/icons/bell";
import { post } from "library/request";
import notifLink from "library/notification";
import Button from "component/button";

export default function () {
  const { notification = [], message = [], app, setting } = useStorage();
  const [type, setType] = useState("notification");

  useEffect(() => {
    post("notification/view", { token: setting.token, type });
  }, [type]);
  const getLink = (link, data) => {
    let _link = notifLink[link];
    if (link == "ticket") {
      _link += data?.id;
    }
    return _link;
  };
  const getText = (text, data) => {
    let notif = t(text);
    if (data) {
      for (let j in data) {
        notif = notif.replace("[" + j + "]", data[j]);
      }
    }
    return notif;
  };
  return (
    <div className="card">
      <div className="card-header d-flex justify-content-between">
        <span className="py-2 d-flex align-items-center">
          <Bell className="fs-5 mx-2" />
          {t("notificationList")}
        </span>
        <div>
          <div className="btn-group">
            <Button
              type={type == "notification" ? "success" : "outline-secondary"}
              onClick={() => setType("notification")}
            >
              {t("notification")}
            </Button>
            <Button
              type={type == "message" ? "success" : "outline-secondary"}
              onClick={() => setType("message")}
            >
              {t("messages")}
            </Button>
          </div>
        </div>
      </div>
      <div className="card-body p-0">
        {type == "notification" ? (
          <>
            {notification.length == 0 ? (
              <EmptyList text="noNotification" />
            ) : (
              <>
                <div className="notify-group">
                  {notification.map((notif) => (
                    <div
                      key={notif.id}
                      className={
                        "notify-group-item " + (notif.view ? "" : "unread")
                      }
                    >
                      <div className="">{getText(notif.text, notif.data)}</div>
                      <div className="fs-sm text-end text-muted">
                        <Time time={notif.createdAt} />
                      </div>
                      {notif.link && (
                        <Link
                          to={"/account/" + getLink(notif.link, notif.data)}
                          className="notify-group-link"
                          onClick={() => app.modal.hide()}
                        />
                      )}
                    </div>
                  ))}
                </div>
                <div className="card-footer d-grid gap-2 mt-3">
                  <Link
                    to="/account/notification"
                    className="btn btn-link text-info px-0"
                    onClick={() => app.modal.hide()}
                  >
                    {t("viewAll")}
                  </Link>
                </div>
              </>
            )}
          </>
        ) : (
          <>
            {message.length == 0 ? (
              <EmptyList text="noMessage" />
            ) : (
              <>
                <div className="notify-group">
                  {message.map((notif) => (
                    <div
                      key={notif.id}
                      className={
                        "notify-group-item " + (notif.view ? "" : "unread")
                      }
                    >
                      <div className="">{notif.text}</div>
                      <div className="fs-sm text-end text-muted">
                        <Time time={notif.createdAt} />
                      </div>
                    </div>
                  ))}
                </div>
                <div className="card-footer d-grid gap-2 mt-3">
                  <Link
                    to="/account/message"
                    className="btn btn-link text-info px-0"
                    onClick={() => app.modal.hide()}
                  >
                    {t("viewAll")}
                  </Link>
                </div>
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
}
