import createPersistedReducer from "../persist";

export default createPersistedReducer("favorite", localStorage);

export const initialState = [];
export function reducer(state, action) {
  if (action == null) {
    return [...initialState];
  } else {
    return [...action.map((e) => e.pair)];
  }
}
