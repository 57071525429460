import createPersistedReducer from "../persist";

export default createPersistedReducer("setting", localStorage);
export const initialState = {
  token: "",
  isLoged: false,
  lang: "fa",
  dir: "rtl",
  countryCode: "ir",
  langFlag: false,
  theme: "dark",
  currency: "USDT",
};

export function reducer(state, action) {
  if (action == null) {
    return { ...initialState };
  } else if ("login" in action) {
    return { ...state, ...action.login, isLoged: true };
  } else {
    return { ...state, ...action };
  }
}
