import createPersistedReducer from "../persist";
import parser from "library/parser";

export default createPersistedReducer("ticker");

export const initialState = {};
export function reducer(state, action) {
  if (action == null) {
    return { ...initialState };
  } else {
    const temp = {};
    for (let ticker of action) {
      const data = parser.ticker(ticker, true);
      temp[data.symbol] = data;
    }
    return { ...state, ...temp };
  }
}
