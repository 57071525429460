import sc_client from "socketcluster-client";
import pubsub from "config/pubsub";
import { isDev } from "./helper";
/**
 * class to handle creation & basic listeners for a connection to a socket cluster server
 * @type {SCClient}
 */
class SCClient {
  constructor(config, token, callback) {
    this.socket = sc_client.create(config);
    this.token = token;
    this.callback = callback;
    this.createListeners();
  }

  createListeners() {
    this.errorListener().then();
    this.connectListener().then();
  }

  /**
   * logs any errors that occur for the socket
   * @returns {Promise<void>}
   */
  async errorListener() {
    for await (let { error } of this.socket.listener("error")) {
      console.error(error);
    }
  }

  /**
   * handles to connect event with a SC server and invokes the server's login function to authenticate
   * @returns {Promise<void>}
   */
  async connectListener() {
    for await (let event of this.socket.listener("connect")) {
      try {
        let data = await Promise.all([
          this.socket.invoke("auth", {
            key: this.token,
          }),
          this.socket.listener("authenticate").once(),
        ]);
        this.callback(this);
      } catch (e) {
        console.error(e.message);
      }
    }
  }

  /**
   * creates a listener on an sc server's channel
   * @param channel
   * @param logic_function
   * @returns {Promise<void>}
   */
  async subscriptionListener(opt, defaultValue, logic_function) {
    let suscribed = await this.socket.subscribe(opt.channel);
    let data = await this.socket.invoke("data", opt);
    defaultValue(data);
    try {
      for await (let data of suscribed) {
        if (logic_function) {
          logic_function(data);
        } else {
          console.log(data);
        }
      }
    } catch (e) {
      console.error(e);
    }
  }
  async unSubscriptionListener(channel) {
    this.socket.unsubscribe(channel);
  }
}
const config = isDev() ? pubsub.development : pubsub.production;
export default (cb) => new SCClient(config, "siavash", cb);
