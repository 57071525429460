import createPersistedReducer from "../persist";

export default createPersistedReducer("message", sessionStorage);

export const initialState = [];

export function reducer(state, action) {
  if (action == null) {
    return [...initialState];
  } else {
    return [...action];
  }
}
