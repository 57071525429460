import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

export default ({ placement = "top", children, title = "" }) => (
  <OverlayTrigger
    placement="top"
    delay={{ show: 50, hide: 400 }}
    overlay={<Tooltip>{title}</Tooltip>}
  >
    {children}
  </OverlayTrigger>
);
