import invert from "lodash/invert";

const tickerObj = {
  t: "time",
  s: "symbol",
  c: "lastPrice",
  o: "openPrice",
  h: "highPrice",
  l: "lowPrice",
  v: "volume",
};

const ticker = (ticker, reverse = false) => {
  let temp = {};
  if (ticker?.s) {
    let src = reverse ? invert(tickerObj) : tickerObj;
    for (let i in src) temp[i] = ticker[src[i]];
    temp.priceChange = temp.lastPrice - temp.openPrice;
    temp.changePercent = +((temp.priceChange / temp.openPrice) * 100).toFixed(
      2
    );
  }
  return temp;
};

export default { ticker };
