import { t } from "locales";
import React from "react";

export default function ({ text = "emptyList", type = "muted" }) {
  return (
    <div className={"d-flex justify-content-center align-items-center p-5 "}>
      <div className={"fs-5 text-" + type}>{t(text)}</div>
    </div>
  );
}
