import React from "react";

export default ({
  loading,
  type = "primary",
  className = "",
  onClick = () => null,
  children,
  disabled = false,
  isSubmit = false,
}) => {
  return (
    <button
      className={"btn btn-" + type + " " + className}
      type={isSubmit ? "submit" : "button"}
      disabled={loading || disabled}
      onClick={onClick}
    >
      {loading ? (
        <div className="spinner-border spinner-border-sm" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      ) : (
        children
      )}
    </button>
  );
};
