import createPersistedReducer from "../persist";
import { t } from "locales";

export default createPersistedReducer("notification", sessionStorage);

export const initialState = [];
export function reducer(state, action) {
  if (action == null) {
    return [...initialState];
  } else {
    const temp = [];
    for (let i of action) {
      let { text, ...data } = i.data;
      temp.push({
        ...i,
        text,
        data,
      });
    }
    if (temp.length == 1 && temp[0]?.view != true) {
      const { text, data = [] } = temp[0];
      let notif = t(text);
      for (let j in data) {
        notif = notif.replace("[" + j + "]", data[j]);
      }
      window.postMessage({ notify: ["warning", notif] }, "*");
    }
    return [...temp, ...state];
  }
}
