import index from "./index-en";
const appName = { name: "RealTex", nameOne: "Real", nameTwo: "Tex" };
export default {
  ...index(appName),
  xbrand: "RealTex",
  brand: appName.name,
  nameOne: appName.nameOne,
  nameTwo: appName.nameTwo,
  register: "Register",
  dashboard: "Dashboard",
  current: "Current",
  history: "History",
  order: "Orders",
  wallet: "Wallet",
  deposit: "Deposit",
  depositRecords: "Deposit Records",
  withdraw: "Withdraw",
  verifyWithdraw: "Verify Withdraw",
  withdrawRecords: "Withdraw Records",
  accountSetting: "Account Setting",
  accountLevel: "Account Level",
  referralReward: "Referral Reward",
  messages: "Messages",
  markets: "Markets",
  exchange: "Exchange",
  trade: "Trade",
  help: "Help",
  registerTitle: "Create a free account",
  registerDesc: "Welcome to " + appName.name,
  email: "Email",
  mobile: "Mobile",
  password: "Password",
  createAccount: "Create Account",
  referral: "Referral",
  referralId: "Referral Id (Optional)",
  agreeTerms: "I have read and agree to the # .",
  TermsOfService: "Terms of Service",
  user_exist: "User Already Registred",
  user_not_found: "User Not Registred",
  invalid_password: "Invalid Password",
  understand: "I understand",
  verify: "Verify",
  verified: "Verified",
  notVerified: "Not Verified",
  verifytitle: "Account verification",
  verifyDesc:
    "Please enter verification code that was sent to (#). The code is valid for 30 minutes.",
  invalid_code: "That code was invalid. Please try again",
  regestredAsk: "Already registered?",
  resend: "Resend",
  login: "Login",
  loginTitle: "Log In",
  loginDesc: "Login with email or mobile",
  registration: "Free registration",
  forgetPassword: "forget password ?",
  unexpectedError: "Unexpected Error",
  change: "Change",
  highest: "High",
  lowest: "Low",
  value: "Value",
  buy: "Buy",
  sell: "Sell",
  both: "Both",
  pair: "Pair",
  lastPrice: "Last Price",
  /* dashboard */
  basicInfo: "Basic info",
  bind: "Bind",
  modify: "Change",
  nickName: "Nickname",
  idVerification: "ID Verification",
  real: "Real",
  legal: "Legal",
  totpAuthentication: "TOTP Authentication",
  totp: "TOTP Code",
  yes: "Yes",
  no: "No",
  reset: "Reset",
  unbound: "Unbound",
  bound: "Bound",
  next: "Next",
  enter2FA: "Enter 2FA Code",
  done: "Done",
  spotAsset: "Spot Asset",
  asset: "Assets",
  coin: "Coin Type",
  name: "Name",
  amount: "Amount",
  available: "Available",
  frozen: "Frozen",
  marketValue: "Value",
  operation: "Operation",
  attention: "Attention",
  attentionInfo:
    "* For your asset security, please pay attention to the following info!",
  attentionDeposit1:
    "The address is ONLY available for [network] deposit. Deposit of other assets will lead to permanent asset loss.",
  attentionDeposit2:
    "Minimum amount per deposit: [min]. Assets less than min amount will NOT be credited until the total sum of deposit reaches the minimum amount.",
  attentionDeposit3:
    "Deposit requires confirmation of the entire network and will be credited after [confirm] confirmations.",
  attentionDeposit4:
    "Deposit requires available for withdrawal after [unlock] confirmations",
  protocolType: "Protocol Type",
  depositAttention: "Must read before # deposit",
  depositConfirm: "I'm aware of the risks and would like to proceed",
  confirm: "Confirm",
  copied: "Copied",
  attentionWithdraw1:
    "Please confirm that your withdrawal address matches the type of your chosen mainnet, in case of unnecessary asset loss.",
  attentionWithdraw2:
    "After withdrawal audition, your asset will be sent out as soon as possible. The actual arrival time is determined by the number of confirmations of the recipient.",
  attentionWithdraw3:
    "Minimum amount per Withdraw: [min]. Assets less than min amount will NOT be send.",
  withdrawInfo: "* Withdrawal address based on # network",
  depositInfo: "* Deposit address based on # network",
  address: "Address",
  optionTag: "Tag , Memo  (Optional)",
  amount: "Amount",
  submit: "Submit",
  balance: "Balance",
  available: "Available",
  inOrder: "In Order",
  pending: "Pending",
  executionValue: "Est. Execution Value",
  executionValueMin: "Est. Value",
  marketPsition: "Buy in the order",
  back: "Back",
  recentTradeActivity: "Recent Trade Activities",
  marketOverview: "Market Overview",
  sellOrders: "Sell Orders",
  buyOrders: "Buy Orders",
  depositAddress: "Sender Address",
  withdrawAddress: "Receiver Address",
  auditing: "Auditing",
  rejected: "Rejected",
  completed: "Completed",
  sended: "Sended",
  emptyList: "No Data",
  successfull: "Successfull",
  withdrawDetails: "Your withdrawal request is being auditing",
  verifywDetails:
    "Your information has been successfully registered and is being reviewed",
  limit: "Limit",
  market: "Market",
  stopLimit: "Stop Limit",
  stopMarket: "Stop Market",
  time: "Time",
  side: "Side",
  type: "Type",
  status: "Status",
  price: "Price",
  totpDesc:
    "The private key can help you reset the TOTP authenticator. If the key is deleted by mistake, you can manually enter it to restore.",
  totpUse:
    "Use TOTP authenticator to scan this QR code or type private key manually; save or write your private key down.",
  copyClipboard: "Copy to clipboard",
  enterOTP: "Enter OTP Code",
  enterOTPwithdraw: "Enter OTP Code (Sended to your email / mobile)",
  privateKey: "Private Key",
  verificationDesc:
    "Please make sure to use your own authentic ID documents for verification. ",
  idNumber: "ID number",
  homePhone: "Home Phone",
  zipcode: "Zip code",
  homeAddress: "Address",
  evidenceTitle: "Picture of the document or lease of residence",
  legalDesc: "Company-specific authentication",
  legalChangeAds: "Announce the latest changes in the company",
  legalIntroduced:
    "Company introduction letter with official letter and stamp and signature on the letterhead",
  legalEconomicCode: "Economic code certificate",
  legalPhone: "Company landline",
  idCardTitle: "Front of ID document",
  idCardNotify:
    "*Please ensure that the documents and declaration are clearly visible, with the text completely exposed.",
  fullImageTitle: "Photo of handhold statement",
  fullImageNotify:
    "Upload a photo of you handholding the front of ID document and statement",
  bad_file: "The submitted image or images are corrupted",
  currentPassword: "Current Password",
  newPassword: "New Password",
  rePassword: "Confirm Password",
  passwordChanged: "Password Successfully Changed",
  emailTo: "Email To",
  smsTo: "Sms To",
  forgetTitle: "Reset Password",
  resetPasswordDesc: "Reset Your Password",
  buyCrypto: "Buy Crypto",
  spend: "Spend",
  receive: "Receive",
  balance_is_not_enough: "Balance is not enough",
  min_trade_error: "Minimun Trade : # ",
  executed: "Executed",
  unExecuted: "Unexecuted",
  avgPrice: "Avg Price",
  logout: "Logout",
  setting: "Setting",
  referralDesc:
    "By inviting your friends, you will be given a # percentage of their trade commission",
  referralCode: "Referral Code",
  referralLink: "Share Link",
  referralInvited: "Invited",
  generateLink: "Generate New Link",
  reward: "Reward",
  invited: "Invited",
  ticket: "Ticket",
  vandar: "Vandar",
  nextpay: "Next Pay",
  chargeAmount: "Charge Amount (Toman)",
  levelType: "Level Type",
  takerFee: "Taker Fee",
  makerFee: "Maker Fee",
  avgTrade: "Avg Trade",
  levelTerms: "Level Terms",
  apply: "Apply",
  total: "Total",
  daylyCryptoLimit: "Dayly Crypto Limit",
  daylyFiatLimit: "Dayly Fiat Limit",
  remain: "Remain",
  used: "Used",
  levelUp: "Level Up",
  tickets: "Tickets",
  newTicket: "New Ticket",
  viewTicket: "View Ticket",
  title: "Title",
  text: "Description",
  category: "Category",
  open: "Open",
  addCard: "Add Card",
  cardId: "Card Id",
  bankName: "bankName",
  cardNumber: "Card Number",
  iban: "Iban",
  showAll: "Show All",
  cancel: "Cancel",
  accepted: "Accepted",
  filling: "Filling",
  incompleted: "Incompleted",
  canceled: "Canceled",
  filled: "Filled",
  view: "View",
  delete: "Delete",
  edit: "Edit",
  firstBindTotp: "To access this page, enable the 2FA code",
  bind2fa: "Bind 2FA",
  firstBindMobile: "Bind your mobile number",
  addressBook: "Address Book",
  addCard: "Add Card",
  addAddress: "Add Address",
  local: "Local",
  global: "Global",
  level: "Level",
  emptyWallet: "Your wallet is empty",
  depositNow: "Deposit Now",
  stopPrice: "Stop Price",
  success_payment: "Success Payment",
  unsuccess_payment: "UnsuccessFull Payment",
  saveToAddressBook: "Save To Address Book",
  cardList: "Card List",
  trades: "Trades",
  or: "Or",
  loginWithGoogle: "Login With Google",
  signUpWithGoogle: "Sign Up With Google",
  botDetected: "Bot Detected",
  withdrawFee: "Withdraw Fee",
  withdrawable: "WithdrawAble",
  max: "Max",
  selected: "Selected",
  perMonth: "Per Month",
  vip: "VIP",
  verification: "Secret Verification",
  attach: "Attach",
  ticketSubmited: "Ticket sent successfully",
  volume: "Volume",
  tag: "Tag",
  orderFee: "fee",
  quantity: "Quantity",
  details: "Details",
  orderDetail: "Order Detail",
  tradeDetail: "Trade Detail",
  currentOrders: "Current Orders",
  finishedOrders: "Orders History",
  devices: "Devices",
  activeDevice: "Active Device",
  manage: "Manage",
  terminate: "Terminate",
  os: "Os",
  browser: "Browser",
  platform: "Platform",
  ip: "Ip",
  notification: "Notification",
  notificationList: "Notification",
  viewAll: "View All",
  noNotification: "No Notification",
  new_login: "New login detected with ip ([ip])",
  new_deposit: "[amount] [coin] Deposited to your accound",
  withdraw_accepted: "Withdraw request accpted for [coin]",
  withdraw_rejected: "Withdraw request rejected for [coin]",
  kyc_accept: "Your Kyc request for lelev [level] accepted",
  kyc_reject: "Your Kyc request for lelev [level] rejected",
  ticket_replyed: "Your ticket has been answered",
  validAmount:
    "The :attribute field must be between min withdraw and availbe balance",
  coinSearch: "Search coin",
  filter: "Filter",
  invalid_parameters: "Invalid Parameters",
  fromDate: "From Date",
  toDate: "To Date",
  topGainers: "Top Gainers",
  topLosers: "Top Losers",
  newCoins: "New Coins",
  topValues: "Top Values",
  withdrawLevel: "Withdraw Level",
  tradeLevel: "Trade Level",
  assetCantDeposit: "This Coin cannot be Deposited !",
  assetCantWithdraw: "This Coin cannot be Withdrawed !",
  totpDeleteLimit:
    "Incase TOTP be removed, you will not have the right to withdraw coin for 24 hours",
};
