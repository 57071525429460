import React, { useState, useEffect } from "react";
import useStorage from "reducer";
import client from "library/pub-sub";
import groupBy from "lodash/groupBy";

let privateSocket = null;
const tickerObj = ["c", "o", "h", "l"];
export default (props) => {
  const {
    asset,
    setPair,
    setAsset,
    setNetwork,
    app,
    setApp,
    setSession,
    setTicker,
    setNotification,
    setWallet,
    session: { baseAsset = [] },
    setting: { isLoged, user, token },
  } = useStorage();
  const [_private, setPrivate] = useState(null);
  const [base, setBase] = useState({});

  useEffect(() => {
    if (baseAsset.length > 0) {
      const temp = {};
      for (let b of baseAsset) {
        temp[b.coin] = b.precision;
      }
      setBase(temp);
    }
  }, [baseAsset]);

  const price = (tickers) => {
    let ticker, i, f, p, _base;
    for (ticker of tickers) {
      for (i of tickerObj) {
        _base = "";
        for (f in base) {
          if (ticker.s.indexOf(f)) {
            _base = f;
            break;
          }
        }
        p = parseFloat(ticker[i]);
        ticker[i] = p < 10 ? ticker[i] : p.toFixed(base[_base] ?? 0);
      }
    }
    return tickers;
  };

  useEffect(() => {
    const sub = client((cl) => {
      cl.subscriptionListener(
        { channel: "public" },
        (initalValue) => {
          let basePairs, quotePairs, assets;
          for (let tmp in initalValue) {
            if (tmp == "pairs") {
              const temp = initalValue[tmp].sort(
                (a, b) => a.priority - b.priority
              );
              basePairs = groupBy(temp, (x) => x.base);
              quotePairs = groupBy(temp, (x) => x.quote);
              setSession({
                basePairs,
                quotePairs,
              });
              setPair(null);
              setPair(initalValue[tmp]);
            } else if (tmp == "assets") {
              setAsset(null);
              assets = initalValue[tmp];
              setAsset(assets);
            } else if (tmp == "networks") {
              setNetwork(null);
              setNetwork(initalValue[tmp]);
            } else if (tmp == "tickers") {
              setTicker(price(initalValue[tmp]));
            }
          }
          const _assets = Object.values(assets);
          const _basePairs = Object.keys(basePairs);
          const baseAsset = _assets.filter((e) => _basePairs.includes(e.id));
          setSession({ baseAsset });
        },
        (onData) => {
          for (let tmp in onData) {
            if (tmp == "tickers") {
              setTicker(price(onData[tmp]));
            } else if (tmp == "ticker") {
              setTicker(price([onData[tmp]]));
            } else if (tmp == "assets") {
              setAsset(null);
              setAsset(onData[tmp]);
            } else if (tmp == "pairs") {
              const temp = onData[tmp].sort((a, b) => a.priority - b.priority);
              const basePairs = groupBy(temp, (x) => x.base);
              const quotePairs = groupBy(temp, (x) => x.quote);
              setSession({
                basePairs,
                quotePairs,
              });
              setPair(null);
              setPair(onData[tmp]);

              const _assets = Object.values(asset);
              const _basePairs = Object.keys(basePairs);
              const baseAsset = _assets.filter((e) =>
                _basePairs.includes(e.id)
              );
              setSession({ baseAsset });
            } else if (tmp == "networks") {
              setNetwork(null);
              setNetwork(onData[tmp]);
            }
          }
        }
      );
    });
    setApp({ sub });
  }, []);
  const notify = (type, text) => {
    app.notify.show(type, text);
  };
  useEffect(() => {
    if (isLoged) {
      setPrivate(user);
      privateSocket = client((cl) => {
        cl.subscriptionListener(
          { channel: "#" + user, type: "private", token },
          (initalValue) => {
            console.log("private channel ", initalValue);
          },
          (onData) => {
            console.log("private channel data", onData);
            for (let tmp in onData) {
              if (tmp == "notification") {
                setNotification([onData[tmp]]);
              }
              if (tmp == "wallet") {
                setWallet([onData[tmp]]);
              }
            }
          }
        );
      });
    } else if (privateSocket) {
      privateSocket.unSubscriptionListener(_private);
    }
  }, [isLoged]);

  return null;
};
