import React, { useContext } from "react";
import storeContext from "reducer/context";

import settingReducer, {
  reducer as _setSetting,
  initialState as _setting,
} from "reducer/actions/setting";
import appReducer, {
  reducer as _setApp,
  initialState as _app,
} from "reducer/actions/app";
import sessionReducer, {
  reducer as _setSession,
  initialState as _session,
} from "reducer/actions/session";
import pairReducer, {
  reducer as _setPair,
  initialState as _pair,
} from "reducer/actions/pair";
import assetReducer, {
  reducer as _setAsset,
  initialState as _asset,
} from "reducer/actions/asset";
import networkReducer, {
  reducer as _setNetwork,
  initialState as _network,
} from "reducer/actions/network";
import walletReducer, {
  reducer as _setWallet,
  initialState as _wallet,
} from "reducer/actions/wallet";
import tickerReducer, {
  reducer as _setTicker,
  initialState as _ticker,
} from "reducer/actions/ticker";
import klineReducer, {
  reducer as _setKline,
  initialState as _kline,
} from "reducer/actions/kline";
import memoryReducer, {
  reducer as _setMemory,
  initialState as _memory,
} from "reducer/actions/memory";
import favoriteReducer, {
  reducer as _setFavorite,
  initialState as _favorite,
} from "reducer/actions/favorite";
import notificationReducer, {
  reducer as _setNotification,
  initialState as _notification,
} from "reducer/actions/notification";
import messageReducer, {
  reducer as _setMessage,
  initialState as _message,
} from "reducer/actions/message";

export default function useStorage() {
  return useContext(storeContext);
}
export const storeContextProvider = (props) => {
  const [setting, setSetting] = settingReducer(_setSetting, _setting);
  const [app, setApp] = appReducer(_setApp, _app);
  const [session, setSession] = sessionReducer(_setSession, _session);
  const [pair, setPair] = pairReducer(_setPair, _pair);
  const [asset, setAsset] = assetReducer(_setAsset, _asset);
  const [network, setNetwork] = networkReducer(_setNetwork, _network);
  const [wallet, setWallet] = walletReducer(_setWallet, _wallet);
  const [ticker, setTicker] = tickerReducer(_setTicker, _ticker);
  const [kline, setKline] = klineReducer(_setKline, _kline);
  const [memory, setMemory] = memoryReducer(_setMemory, _memory);
  const [favorite, setFavorite] = favoriteReducer(_setFavorite, _favorite);
  const [message, setMessage] = messageReducer(_setMessage, _message);
  const [notification, setNotification] = notificationReducer(
    _setNotification,
    _notification
  );

  return (
    <storeContext.Provider
      value={{
        setting,
        setSetting,
        app,
        setApp,
        session,
        setSession,
        pair,
        setPair,
        asset,
        setAsset,
        network,
        setNetwork,
        wallet,
        setWallet,
        ticker,
        setTicker,
        kline,
        setKline,
        memory,
        setMemory,
        favorite,
        setFavorite,
        notification,
        setNotification,
        message,
        setMessage,
      }}
    >
      {props.children}
    </storeContext.Provider>
  );
};
