import React, { useEffect, useRef } from "react";
import { withRouter, useHistory } from "react-router-dom";
import Toolbar from "component/navbar";
import Modal from "component/modal";
import Alert from "component/alert";
import Notify from "component/notify";
import AppRoutes from "Routes";
import useStorage from "reducer";
import UseLive from "library/live";
import i18next from "locales";
import { get, post } from "library/request";
import { swap } from "library/helper";
import useWindowSize from "component/resize";
import { t } from "locales";

const foFont = ["fa", "ar"];

const App = (props) => {
  const [width, height] = useWindowSize();
  const {
    setting,
    setSetting,
    setApp,
    setWallet,
    setSession,
    setNotification,
    setMessage,
  } = useStorage();
  const modalRef = useRef();
  const alertRef = useRef();
  const notifyRef = useRef();
  const history = useHistory();
  useEffect(() => {
    const isMobile = width < 800;
    setApp({ isMobile });
  }, [width]);

  useEffect(() => {
    setApp({
      modal: modalRef.current,
      alert: alertRef.current,
      notify: notifyRef.current,
    });
    window.addEventListener("message", (event) => {
      if (event?.data?.login) {
        setSetting(null);
        history.replace("/login");
      }
    });
    if (setting?.langFlag != true) {
      get("user/detect").then((res) => {
        if (res?.countryCode) {
          const code = res.countryCode.toLowerCase();
          setSetting({
            countryCode: code,
            lang: code == "ir" ? "fa" : code,
            langFlag: true,
          });
        }
      });
    }
  }, []);

  useEffect(() => {
    i18next.changeLanguage(setting?.lang);
    setSetting({
      dir: foFont.includes(setting?.lang) ? "rtl" : "ltr",
    });
    const body = document.body.classList;
    body.remove("rtl");
    if (foFont.includes(setting?.lang)) {
      body.add("rtl");
    }
  }, [setting?.lang]);

  useEffect(() => {
    if (setting?.isLoged == true) {
      post("account/info", { token: setting.token }).then((res) => {
        if (res?.message == "Success") {
          setApp({ user: res?.data });
        }
      });

      setWallet(null);
      post("asset/wallets", { token: setting.token }).then((res) => {
        if (res?.message == "Success") {
          setWallet(res.data);
        }
      });

      setNotification(null);
      post("notification/top", { token: setting.token }).then((res) => {
        if (res?.message == "Success") {
          const { notification, message } = res?.data;
          setNotification(notification);
          setMessage(message);
        }
      });
    }
  }, [setting?.isLoged]);

  useEffect(() => {
    post("data/constans").then((res) => {
      let _constans = {};
      if (res?.message == "Success") {
        const { setting: _setting, constans } = res.data;
        for (let i in constans) _constans[i] = swap(constans[i]);
        setSession({ constans: _constans, setting: _setting });
      }
    });
  }, []);

  useEffect(() => {
    const html = document.documentElement.classList;
    html.remove("dark");
    html.remove("light");
    html.add(setting?.theme);
  }, [setting?.theme]);

  return (
    <>
      <Modal ref={modalRef} />
      <Alert ref={alertRef} />
      <Notify ref={notifyRef} />
      <UseLive />
      <Toolbar />
      <div className="mt-2">
        <AppRoutes />
      </div>
    </>
  );
};

export default withRouter(App);
