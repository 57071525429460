import React from "react";
import Button from "component/button";
import useStorage from "reducer";

export const currencies = [];

export default function () {
  const {
    setting,
    setSetting,
    app,
    session: { baseAsset = [] },
  } = useStorage();

  const changeCurrency = (currency) => {
    app.modal.hide();
    setSetting({ currency });
  };
  return (
    <div className={"row my-3 px-2"}>
      {baseAsset.map((item, i) => (
        <Button
          key={item.id}
          onClick={() => changeCurrency(item.coin)}
          type={
            "link col-sm-6 col-md-6 my-2 " +
            (setting.currency == item.coin ? "text-primary " : "")
          }
        >
          {item.coin}
        </Button>
      ))}
    </div>
  );
}
