import createPersistedReducer from "../persist";
export default createPersistedReducer("asset", sessionStorage);

export const initialState = {};
export function reducer(state, action) {
  if (action == null) {
    return { ...initialState };
  } else {
    const temp = {};
    for (let data of action) {
      temp[data.id] = data;
    }
    return { ...state, ...temp };
  }
}
