import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { t } from "locales";
import useStorage from "reducer";
import Logo from "assets/img/logo.png";
import LogoDark from "assets/img/logo-dark.png";
import { useLocation, useHistory } from "react-router-dom";
import Power from "react-bootstrap-icons/dist/icons/power";
import dashboard from "react-bootstrap-icons/dist/icons/speedometer2";
import markets from "react-bootstrap-icons/dist/icons/folder";
import exchange from "react-bootstrap-icons/dist/icons/arrow-left-right";
import Globe from "react-bootstrap-icons/dist/icons/globe";
import Bell from "react-bootstrap-icons/dist/icons/bell";
import Light from "react-bootstrap-icons/dist/icons/brightness-high";
import Dark from "react-bootstrap-icons/dist/icons/moon";
import CurrencyIcon from "react-bootstrap-icons/dist/icons/currency-dollar";
import Offcanvas from "react-bootstrap/Offcanvas";
import Sidebar from "component/sidebar";
import Notification from "component/notification";
import Language, { langs } from "component/language";
import Currency, { currencies } from "component/currency";
import Button from "component/button";
import { post } from "library/request";

const signed = [
  { icon: dashboard, title: "dashboard", link: "/account/dashboard" },
  { icon: markets, title: "markets", link: "/markets" },
  { icon: exchange, title: "exchange", link: "/trade/BTC-USDT" },
];
const geuest = [
  { icon: markets, title: "markets", link: "/markets" },
  { icon: exchange, title: "exchange", link: "/trade/BTC-USDT" },
];
export default () => {
  const { setting, setSetting, app, notification = [] } = useStorage();
  const location = useLocation();
  const history = useHistory();
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [isRoot, setIsRoot] = useState(true);

  useEffect(() => {
    setIsRoot(location.pathname == "/");
    setShow(false);
  }, [location.pathname]);
  const isPathActive = (path) => {
    return location.pathname == path;
  };
  const showLang = () => {
    app.modal.show(<Language />, { type: "sm" });
  };
  const showCurrency = () => {
    app.modal.show(<Currency />, { type: "sm" });
  };
  const showNotify = () => {
    app.modal.show(<Notification />, { type: "md" });
  };
  const changetheme = () => {
    setSetting({ theme: setting.theme == "dark" ? "light" : "dark" });
  };
  const logOut = () => {
    post("login/terminate", { id: setting.token, token: setting.token });
    history.push("/");
    setSetting(null);
  };
  const userLang = setting?.lang ? setting?.lang : "en";
  const langTitle = langs.filter((lang) => lang.id == userLang)?.[0]?.title;
  const notify = notification.some((e) => e.view != true);
  const renderLogo = () => {
    return (
      <Link to="/" className="navbar-brand text-white ">
        <img src={setting.theme == "dark" ? LogoDark : Logo} className="" />
        <div>
          {/* <span className="fs-3 text-uppercase">{t("brand")}</span> */}
          <div className="fs-3 mx-3">
            <span
              style={{
                color: setting.theme == "dark" ? "#fff" : "#00457C",
              }}
            >
              {t("nameOne")}
            </span>
            <span style={{ color: "#0079C1" }}>{t("nameTwo")}</span>
          </div>
        </div>
      </Link>
    );
  };
  return (
    <>
      <nav
        className={
          "navbar navbar-expand-lg " + (isRoot ? "navbar-root" : "navbar-glass")
        }
      >
        <div className="container-fluid p-2">
          {renderLogo()}
          <button className="navbar-toggler" type="button" onClick={handleShow}>
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse">
            <ul
              className={
                "navbar-nav m" +
                (setting?.dir == "ltr" ? "e" : "s") +
                "-auto mb-2 mb-lg-0"
              }
            >
              {(setting.isLoged ? signed : geuest).map((item, i) => (
                <li
                  key={i}
                  className={
                    "nav-item " + (isPathActive(item.link) ? "active" : "")
                  }
                >
                  <Link to={item.link} className="nav-link">
                    <item.icon className="text-secondary mx-1 fs-5" />
                    {t(item.title)}
                  </Link>
                </li>
              ))}
            </ul>
            <div className="d-flex align-items-center">
              {!setting.isLoged ? (
                <>
                  <Link to="/login">
                    <button
                      className={
                        "d-flex btn btn-outline-light btn-rounded px-4 fw-bold"
                      }
                    >
                      {t("login")}
                    </button>
                  </Link>
                  <div className="diver" />
                  <Link to="/register">
                    <button className="d-flex btn btn-gradient-primary btn-rounded px-4 fw-bold">
                      {t("register")}
                    </button>
                  </Link>
                </>
              ) : (
                <>
                  <button
                    className="d-flex btn btn-link btn-rounded px-3 "
                    onClick={logOut}
                  >
                    <Power className="fs-4" />
                  </button>
                  <button
                    className="d-flex btn btn-link btn-rounded px-3 position-relative"
                    onClick={showNotify}
                  >
                    <Bell className="fs-4" />
                    {notify && (
                      <span className="notify-dot p-1 bg-danger rounded-circle"></span>
                    )}
                  </button>
                </>
              )}
              <button
                className="d-flex btn btn-link btn-rounded px-3"
                onClick={changetheme}
              >
                {setting.theme == "dark" ? (
                  <Light className="fs-4" />
                ) : (
                  <Dark className="fs-4" />
                )}
              </button>
              <button
                className="d-flex btn btn-link btn-rounded px-3"
                onClick={showCurrency}
              >
                <CurrencyIcon className="fs-4" />
              </button>
              <button
                className="d-flex btn btn-link btn-rounded px-3"
                onClick={showLang}
              >
                {/* {langTitle} */}
                <Globe className="fs-4" />
              </button>
            </div>
          </div>
        </div>
      </nav>
      <Offcanvas
        show={show}
        onHide={handleClose}
        placement={setting.dir == "rtl" ? "end" : "start"}
      >
        <Offcanvas.Header closeButton>{renderLogo()}</Offcanvas.Header>
        <Offcanvas.Body>
          <Sidebar Offcanvas={true} />
        </Offcanvas.Body>
        <Offcanvas.Header>
          <div className="w-100 d-flex justify-content-between">
            {setting.isLoged && (
              <>
                <button
                  className="btn btn-link btn-rounded px-3"
                  onClick={logOut}
                >
                  <Power className="fs-4" />
                </button>
                <button
                  className="d-flex btn btn-link btn-rounded px-3 position-relative"
                  onClick={showNotify}
                >
                  <Bell className="fs-4" />
                  {notify && (
                    <span className="notify-dot p-1 bg-danger rounded-circle"></span>
                  )}
                </button>
              </>
            )}

            <button
              className="btn btn-link btn-rounded px-3"
              onClick={changetheme}
            >
              {setting.theme == "dark" ? (
                <Light className="fs-4" />
              ) : (
                <Dark className="fs-4" />
              )}
            </button>
            <button
              className="btn btn-link btn-rounded px-3"
              onClick={showCurrency}
            >
              <CurrencyIcon className="fs-4" />
            </button>
            <button
              className="btn btn-link btn-rounded px-3"
              onClick={showLang}
            >
              {/* {langTitle} */}
              <Globe className="fs-4" />
            </button>
          </div>
        </Offcanvas.Header>
      </Offcanvas>
    </>
  );
};
